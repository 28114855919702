import React from 'react';
import mixpanel from 'mixpanel-browser';
import { AuthProvider, MixpanelContext } from './src/context';
import './src/styles/global.css';

export const wrapRootElement = ({ element }) => {
  mixpanel.init('ab6153ea891a82ae2ef10839712f57b1');

  return (
    <AuthProvider>
      <MixpanelContext.Provider value={mixpanel}>
        {element}
      </MixpanelContext.Provider>
    </AuthProvider>
  );
};
