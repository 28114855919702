import React from 'react';
import { MsalProvider } from '@azure/msal-react';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';

const config: Configuration = {
  auth: {
    clientId: 'd81d659c-4d08-49aa-9cb1-38ba2136fca5',
    authority: 'https://login.microsoftonline.com/5b268d57-2a6f-4e04-b0de-6938583d5ebc',
    redirectUri: 'https://aop-dev.twqa.ca/auth-response',
  },
  cache: {
    cacheLocation: 'localStorage', // configures where your cache will be stored
    storeAuthStateInCookie: true, // true if you are having issues on IE11 or Edge
  },
};

const pca = new PublicClientApplication(config);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  return <MsalProvider instance={pca}>{children}</MsalProvider>;
}
